import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Pocket1col from "src/components/pocket1col"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"

const pocketData = import("./data/data.json");

export default function spabm2() {

  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosureNewtab data={pocketData}></CtaDisclosureNewtab>;
  }
  else{
    disclosure = <CtaDisclosureNewtab data={pocketData}></CtaDisclosureNewtab>;
  }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

        .footerlinkssingle-module--br {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        #footerlinkssingle-module--footerlinkssingle .footerlinkssingle-module--br {
          margin: 50px auto 0;
        }


    `}
    </style>

    <title>Track your Water Intake | GetSipApp.com</title></Helmet>
    <section>
      <Pocket1col data={pocketData}>
      {disclosure}
    </Pocket1col>
      </section>
    </HomepageLayout>
  )
}
