import React from "react"
import Styles from "./css/footerlinkssingle.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"

class FooterlinksSingle extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      display: "block",
      unsubscribe: false
    }
    if(props.data){
      this.state.data = props.data;
    }
    if(props.unsubscribe){
      this.setState({unsubscribe: props.unsubscribe});
    }
  }
  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }
  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
  }
  render() {
    let unsubscribe = null;
    if(this.state.unsubscribe){
      unsubscribe = <span> | <a href="/unsubscribe/" target="_blank">Unsubscribe</a></span>;
    }
    return (
      <div id={Styles.footerlinkssingle}>
      <div className ={Styles.br} style={{ display: this.state.display }}>
        Tightrope Interactive
          | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/uninstall/" target="_blank">Uninstall</a> {unsubscribe} | <a href="/contact-us/" target="_blank">About Us</a> <br />
          All trademarks are property of their respective owners | <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
      </div>
    </div>
    )
  }
}

export default FooterlinksSingle
